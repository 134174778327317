@tailwind base;
@tailwind components;
@tailwind utilities;

/* Transition globale */
html {
  @apply bg-gray-50 text-gray-800 dark:bg-gray-900 dark:text-gray-200;
  transition: background-color 0.3s, color 0.3s;
}

/* Supprime les flèches flottantes dans les champs */
input::-webkit-calendar-picker-indicator,
textarea::-webkit-calendar-picker-indicator {
  display: none !important;
}


/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 1s ease-in-out;
}

.animate-slide-up {
  animation: slideUp 1s ease-in-out;
}
